const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://cranky-golick-dadc38.netlify.app',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: '',
    logoLink: 'sdsf',
    title:
      "TramwayJS",
    githubUrl: 'https://github.com/tramwayjs',
    helpUrl: '',
    tweetText: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction',
      '/setup',
      '/concepts',
      '/components',
      '/cli',
      '/recipes',
    ],
    collapsedNav: [
      // '/concepts',
      // '/anatomy',
      // '/cli',
      // '/recipes',
    ],
    links: [
      // { text: 'Hasura', link: 'https://hasura.io' }
    ],
    frontline: false,
    ignoreIndex: true,
    title:
      "TramwayJS",
  },
  siteMetadata: {
    title: 'A framework for applications with high reuse | TramwayJS',
    description: 'Documentation built with mdx. Powering hasura.io/learn ',
    ogImage: null,
    docsLocation: 'https://github.com/tramwayjs/documentation/tree/master/content',
    favicon: 'https://graphql-engine-cdn.hasura.io/img/hasura_icon_black.svg',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'TramwayJS Documentation',
      short_name: 'TramwayDocs',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
